.select-wrapper {
  position: relative;
  width: 100%;
  select {
    width: 100%
  }
  .MuiFormControl-root {
    width: 100% !important;
  }
  &__actions {
    position: absolute;
    right: 30px;
    top: 18px;
    cursor: pointer;
  }
}
