body {
  // direction: ltr;
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

a {
  text-decoration: none;
  color: #cb0c9f;

  &:hover {
    opacity: 0.7;
  }
}

.loading-text-box {
  position: absolute;
  z-index: 99999;
  top: 50%;
  width: 100%;
  text-align: center;
}

.loading-text-box p {
  position: relative;
  z-index: 99999;
}
.sticky-options {
  top: 80px!important;
  background-color: #ffffff;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;

  .MuiGrid-container {
    margin-bottom: 0px!important;
    padding: 10px 8px;
  }
}

.requirement,
.error {
  color: #ff0000;
}
.has-cursor {
  cursor: pointer;
}
.paper {
  padding: 25px;
}
.main-logo {
  height: 35px;
  border-radius: 5px;
}
.chart-wrapper {
  width: 100%;
  // min-height: 250px;
  height: 100%;
  min-height: 400px;
  border-radius: 5px;
  padding: 5px;
  .MuiTypography-root {
    justify-content: center;
    font-size: 14px !important;
  }
}
.chart-wrapper .title {
  text-align: center;
  color: #333333;
  font-size: 18px;
}

.chart-wrapper .no-data-text {
  text-align: center;
  color: #333333;
  font-size: 18px;
  text-decoration: underline;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search .MuiFormControl-root {
  width: 100% !important;
}
.MuiFormControl-root {
  width: 100%;
}
.MuiAccordionSummary-content {
  align-items: center;
}
.MuiAccordionSummary-root {
  padding: 0!important;
}
.link-button {
  background: transparent;
  padding: 0!important;
  color: #373636!important;
  font-weight: 400!important;
  display: flex;
  align-items: center;
  font-size: 14px;

  &:hover {
    background: transparent!important;
    opacity: 0.7;
  }

  &.active {
    color: #cb0c9f!important;

    .MuiSvgIcon-root {
      color: #cb0c9f!important;
    }
  }
}

.drawer-nav, .MuiAccordion-root {
  padding-left: 8px;
  padding-right: 8px;
}

.drawer-opened {
  .drawer-nav, .MuiAccordion-root {
    padding-left: 16px;
  }
}

.drawer-closed {
  .MuiPaper-root {
    width: calc(72px + 1px) !important;
  }
}

header {
  height: 80px;
}

.MuiTableCell-head {
  font-weight: 700!important;
}

.highcharts-title {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}
.MuiAccordion-root {
  border-radius: 0 !important;
  box-shadow: none !important;
}

.MuiBox-root .MuiPaper-root {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.MuiPaper-root .MuiAccordion-root {
  box-shadow: none !important;
  transition: none !important;
}

.MuiDialog-paperFullScreen {

  .MuiDialogTitle-root  {
    display: none;
  }

  .MuiDialogContent-root {
    padding: 0;
  }
}

button {
  text-transform: none !important;
}
.user-details-row {
  max-width: 400px;
  position: relative;
  &__icon {
    position: absolute;
    right: 10px;
  }
}

.MuiFormControl-root .MuiSelect-outlined ~  fieldset {
  border-color: #17c1e8;
}

.list-checkbox {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  li {
    padding-left: 12px;
    padding-right: 0;
    margin-right: 0;
    width: initial;
  }
}

.label-checkbox {

  .MuiFormControlLabel-label {
    padding: 14px 31px;
    background-color: #b3b3b3;
  }

  .MuiCheckbox-root {
    display: none;

    &.Mui-checked {

      ~ .MuiFormControlLabel-label {
        background-color: #000000;
        color: #ffffff;
      }
    }
  }
}

.connection-modal-content {
  display: flex;

  .MuiIconButton-root {
    padding: 0;
  }

  .MuiRadio-root  {

    &.Mui-checked {
      color: #3c64b1;
    }
  }

  .content-left, .content-right {
    min-height: 100vh;
  }

  .content-left {
    width: 40%;
    padding: 100px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f4f5f4;

    .MuiFormControl-root {
      align-items: center;
      max-height: 500px;
      overflow-y: auto;
    }

    &.without-campaigns {
      width: 100%;
      align-items: flex-start;
      padding-left: 220px;

      .MuiFormControl-root {
        align-items: flex-start;
      }
    }
  }

  .content-right {
    width: 60%;
    padding: 50px 60px;
    display: flex;
    flex-direction: column;
  }

  .action-buttons {
    margin-top: auto;
    text-align: right;

    button {
      border-radius: 0
    }

    .MuiButton-contained {
      background-color: #3c64b1;
    }

    .MuiButton-outlined {
      border-color: #3c64b1;
      color: #3c64b1;
    }
  }
}

.auth-pages, {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;

  form {
    width: 100%;
  }
  .paper {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}

@media screen and (max-width: 480px){
  .auth-pages {
    .paper {
      max-width: 245px;
    }
  }
}


@media screen and (max-width: 900px) {

  .connection-modal-content .content-left.without-campaigns {
    padding-left: 30px;
    align-items: center;
    min-height: 100vh;
  }

  .connection-modal-content .content-left.without-campaigns .MuiFormControl-root {
    align-items: center;
  }

  .connection-modal-content {
    flex-wrap: wrap;

    .content-right, .content-left {
      width: 100%;
      padding: 30px;
      height: initial;
    }

    .content-right {
      align-items: center;
    }

    .action-buttons {
      margin-top: 10px;
    }
  }
}