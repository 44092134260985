.input-wrapper {
  position: relative;
  width: 100%;
  .MuiFormControl-root{
    width: 100%;
  }

  input {
    width: 100%
  }
  &__actions {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }
}
