.side-bar {
  display: flex;
  justify-content: center;
}
.breadcrumbs {
  background: #f8f8f8;
  padding: 12px;
}

.drawer-nav {
  min-height: 48px;
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.12);

  svg {
    margin-right: 5px;
  }

  a {
    padding: 0 16px;
    display: flex;
    align-items: center;
    color: #151414;
    text-decoration: none;

    &:hover {
      opacity: 0.7;
    }

    p {
      font-size: 14px;
    }
  }
}

.drag-drop-upload label {
  height: 100px;
  margin-right: auto;
  margin-left: auto;
  min-width: initial;
}

.accordion-items {
  svg {
    margin-right: 5px;
  }

  p {
    font-size: 14px;
  }

  .nav-items {
    padding: 0;
    margin-left: 14px;

    li {
      font-size: 14px;
      width: 178px;
      max-width: 178px;

      a {
        color: #373636;
        text-decoration: none;

        &:hover {
          opacity: 0.7;
        }
      }

      button {
        justify-content: flex-start;
      }
    }
  }
}

.MuiAccordion-region {
  div {
    padding: 0 !important;
  }
}

.MuiAccordion-root {

  &.Mui-expanded {

    &::before {
      opacity: 1 !important;
    }
  }
}