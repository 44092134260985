header {
  background-color: #fff !important;
  color: #000 !important;

  a {
    font-weight: 600;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    display: inline-block;
    margin-right: 12px;
    color: #232222;
  }
}
