.widget-number {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  .MuiSvgIcon-root {
    font-size: 80px;
    background: #f8f8f8;
    padding: 16px;
  }
  + div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__content {
    margin-left: 32px;
    h5 {
      text-transform: uppercase;
      font-size: 16px;
      margin-top: 0;
      font-weight: 400;
      margin-bottom: 8px;
    }
    span {
      font-size: 24px;
      font-weight: bold;
    }
  }
}
