footer {
  color: $gray !important;
  padding-top: 30px;
  padding-bottom: 30px;
  background: $black;

  .footer-cta {
    border-bottom: 1px solid #373636;
  }

  .social-links {
    display: flex;
    padding-top: 0;
    padding-bottom: 0;

    li {
      padding-right: 5px;

      &:first-child {
          padding-left: 0;
      }
    }

    a {
      color: #7e7e7e;
      transition: all 0.2s;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .nav-list {

    a {
      color: #757575;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
      font-size: 15px;
      transition: all 0.2s;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .cta-text {
    display: inline-block;
    h5 {
      color: $gray;
      font-size: 16px;
      margin-bottom: 2px;
    }
  }

  .footer-logo {
    margin-bottom: 22px;

    a {
      transition: all 0.2s;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .footer-text {
    margin-top: 32px;
    p {
      max-width: 800px;
      text-align: center;
      font-size: 14px;
      color: rgba(#7e7e7e, 0.7);
    }
  }
}
