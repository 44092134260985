.source {
  &__icons {
    text-align: center;
    cursor: pointer;

    .MuiSvgIcon-root {
      font-size: 200px !important;
    }
    img {
      margin-top: 25px;
      width: 190px;
    }
  }
}
.sources-list {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
