.landing-wrapper {
  margin-top: 65px;
  text-align: center;
  h2 {
    font-size: 28px !important;
  }
  &__banner {
    position: relative;
    height: 500px;
    width: 100%;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: center;
    display: flex;
    justify-content: center;

    color: #000;
    &:before {
      background-image: url("../../assets/images/landing-bg.png");
      opacity: 0.2;
      filter: alpha(opacity=20);
      background-size: cover;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.65;
      content: "";
    }
    h1 {
      position: relative;
      max-width: 700px;
      font-size: 32px;
      font-weight: bold;
    }
    .details {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      max-width: 700px;
    }
    .btns {
      margin-top: 20px;
    }
  }
  &__features {
    margin-top: 20px;
    text-align: center;
  }
}
